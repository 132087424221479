import OpenLayers from 'lib/OpenLayers-2.12/OpenLayers.debug';
import AIS from '../../AIS';

/**
 * Путь к файлу с описанием слоев в формате TileJSON
 *  - https://github.com/mapbox/tilejson-spec
 *  - https://tileserver.readthedocs.io/en/latest/endpoints.html#tilejson-arrays
 * @type {string}
 */
const tileserver_path = 'https://maptiles.digital-roads.ru/data.json';
const geoserver_path = 'https://maps.digital-roads.ru/geoserver/gwc/service/wmts?REQUEST=GetCapabilities';
const geoserver_tms_prefix = 'https://maps.digital-roads.ru/geoserver/gwc/service/tms/1.0.0/';
const mapproxy_path = 'https://maps.digital-roads.ru/mapproxy_index.json';

/**
 * Путь, который возвращает название проекта
 * @type {string}
 */

/**
 * Получить параметры для создания слоя OpenLayers из описания слоя в формате TileJSON
 *
 * @param {Object} layer_data - конфигурация AIS
 * @returns {Array} массив из трех значений: имя слоя, шаблон URL, настройки слоя
 */
function getLayerParameters(layer_data) {
  // массив с разрешениями уровней слоя (до 25 зума)
  const server_resolutions = [
    156543.03390625,
    78271.516953125,
    39135.7584765625,
    19567.87923828125,
    9783.939619140625,
    4891.9698095703125,
    2445.9849047851562,
    1222.9924523925781,
    611.4962261962891,
    305.74811309814453,
    152.87405654907226,
    76.43702827453613,
    38.218514137268066,
    19.109257068634033,
    9.554628534317017,
    4.777314267158508,
    2.388657133579254,
    1.194328566789627,
    0.5971642833948135,
    0.29858214169740677,
    0.14929107084870338,
    0.07464553542435169,
    0.037322767712175846,
    0.018661383856087923,
    0.009330691928043961,
  ];

  const layer_settings = {
    transitionEffect: 'resize',
    isBaseLayer: false,
    visibility: false,
    numZoomLevels: Math.max(23, layer_data.maxzoom), // если максимальный зум меньше 23, то будем интерполировать до 23
    serverResolutions: server_resolutions.slice(0, layer_data.maxzoom + 1),
    tileOptions: {
      crossOriginKeyword: null,
    },
    attribution: '',
  };

  return [
    layer_data.name,
    layer_data.tiles[0].replace(/{[xyz]}/g, '$$$&'), // '{x}' -> '${x}' и т. д.
    layer_settings,
  ];
}

/**
 * Получить список кастомных слоёв из наших Tileserver GL, Mapproxy, Geoserver.
 *
 * @param {Object} config - конфигурация AIS
 * @returns {Promise<Array<OpenLayers.Layer.OSM>>} массив слоёв OpenLayers
 */
async function getLayers(config) {
  const layers = [];
  let projname = '';
  if (!AIS.featureEnabled('hide_custom_map_layers')) {
    // Получаем название проекта
    if ('projectName' in AIS.environment) {
      projname = AIS.environment.projectName;
    } else {
      console.error('Error while fetching project name'); // eslint-disable-line no-console
    }
    // Получаем список слоев Tileserver GL и оставляем только те, название которых начинается как название проекта
    let layers_response;
    let layers_data;

    layers_response = await fetch(tileserver_path);
    if (layers_response.ok) {
      layers_data = await layers_response.json();
      layers_data.forEach((layer_data) => {
        if (
          layer_data.id.startsWith(projname)
          || layer_data.basename.startsWith(config.name)
        ) {
          layers.push(new OpenLayers.Layer.OSM(...getLayerParameters(layer_data)));
        }
      });
    } else {
      console.error('Error while fetching Tileserver GL data.json'); // eslint-disable-line no-console
    }

    // Получаем список слоев Mapproxy, если это проект Москва
    if (projname.startsWith('msk')) {
      layers_response = await fetch(mapproxy_path);
      if (layers_response.ok) {
        layers_data = await layers_response.json();
        layers_data.forEach((layer_data) => {
          layers.push(new OpenLayers.Layer.OSM(...getLayerParameters(layer_data)));
        });
      } else {
        console.error('Error while fetching mapproxy_index.json'); // eslint-disable-line no-console
      }
    }

    // Получаем список слоев Geoserver и показываем те, которые предназначены для этого проекта
    const geoserver_response = await fetch(geoserver_path);
    if (geoserver_response.ok) {
      const geoserver_data = await geoserver_response.text();
      const format = new OpenLayers.Format.WMTSCapabilities();
      const capabilities = format.read(geoserver_data);
      const srid = 'EPSG:900913';
      for (const wmts_layer of capabilities.contents.layers) {
        const workspace = wmts_layer.identifier.split(':')[0];
        const abstract = ('abstract' in wmts_layer) ? wmts_layer.abstract.trim() : '';
        const project_names = abstract.split(/[ ,]+/);
        if (workspace.startsWith(projname) || project_names.includes(projname)) {
          const layer_data = {
            maxzoom: 24,
            name: wmts_layer.title,
            tiles: [`${geoserver_tms_prefix}${wmts_layer.identifier}@${srid}@png/{z}/{x}/{y}.png?flipY=true`],
          };
          layers.push(new OpenLayers.Layer.OSM(...getLayerParameters(layer_data)));
        }
      }
    } else {
      console.error('Error while fetching GeoServer WMTS GetCapabilities'); // eslint-disable-line no-console
    }
  }

  return layers.sort((l1, l2) => l1.name.localeCompare(l2.name, undefined, { numeric: true }));
}

export default getLayers;
